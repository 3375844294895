import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PageLayout } from "../../components/layouts"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"
import MediaItem from "./MediaItem"
import "./index.scss"

const MediaPage = props => {
  const { data } = props
  const posts = data.allMarkdownRemark.edges

  return (
    <PageLayout background="#f5f7f9" showBlog={false}>
      <SEO title="Long Term Care Info | Articles, Videos, and Pocasts | LTCPG" />
      <SEO ogDescription="Accurate long term care info is hard to come by. Corey Rieck and his team at The Long Term Care Planning Group focus on your LTC education." />

      <PageHeader
        header="Long Term Care Information"
        subheader="Learn more about how Long Term Care Insurance can help protect your family and investments with our informative articles, videos, and podcasts."
      />
      <div className="media-items">
        {posts.map(({ node }) => (
          <MediaItem
            date={new Date(node.frontmatter.date).toLocaleDateString()}
            slug={node.fields.slug}
            title={node.frontmatter.title}
            titletag={node.frontmatter.titletag}
            image={node.frontmatter.previewImage}
            description={node.frontmatter.description}
            posttype={node.frontmatter.posttype}
          />
        ))}
      </div>
    </PageLayout>
  )
}

export default MediaPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { ne: "person" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            titletag
            description
            previewImage
            date
            posttype
          }
        }
      }
    }
  }
`
