import React from 'react';
import './index.scss';

const PageHeader = (props) => {
  return (
    <div className="page-header">
      <h1>{props.header}</h1>
      {
        props.subheader &&
          <p>{props.subheader}</p>
      }
      {
        props.children
      }
    </div>
  );
}

export default PageHeader;
